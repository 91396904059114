import {getDistributionRequest} from '../api/distributions';

import{
  GET_DISTRIBUTION,
  GET_DISTRIBUTION_FAILURE,
  GET_DISTRIBUTION_SUCCESS,
} from './types';

function getDistribution() {
  return { type: GET_DISTRIBUTION }
}

function getDistributionSuccess(json) {
  return{
    type: GET_DISTRIBUTION_SUCCESS,
    payload: json
  }
}

function getDistributionFailure(error) {
  return{
    type: GET_DISTRIBUTION_FAILURE,
    payload: error
  }
}

export default function requestGetDistribution(token, id) {
  return dispatch => {
    dispatch(getDistribution());
    return getDistributionRequest(token, id)
      .then(json => dispatch(getDistributionSuccess(json)))
      .catch((error) => {
        return error.json().then((errors) => {
          return dispatch(getDistributionFailure(errors));
        })
      });
  };
}
