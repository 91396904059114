import {getAllAdvanceRequestsRequest} from '../api/advance-requests';

import{
    GET_ALL_ADVANCE_REQUESTS,
    GET_ALL_ADVANCE_REQUESTS_SUCCESS,
    GET_ALL_ADVANCE_REQUESTS_FAILURE
} from './types';


function getAllAdvanceRequests(){
    return{
        type: GET_ALL_ADVANCE_REQUESTS
    }
}

function getAllAdvanceRequestsSuccess(json){
    return{
        type: GET_ALL_ADVANCE_REQUESTS_SUCCESS,
        payload: json
    }
}

function getAllAdvanceRequestsFailure(error){
    return{
        type: GET_ALL_ADVANCE_REQUESTS_FAILURE,
        payload: error
    }
}


export default function requestGetAllAdvanceRequests(token) {
    return dispatch => {
        dispatch(getAllAdvanceRequests());
        return getAllAdvanceRequestsRequest(token)
            .then((json) => {
                return dispatch(getAllAdvanceRequestsSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getAllAdvanceRequestsFailure(errors));
                })
            });
    };
}
