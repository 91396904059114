import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Table from '../blocks/table';
import ButtonLink from '../blocks/button-link';
import Icon from '../blocks/icon';
import ProcessedModal from '../blocks/processed-modal';
import DeleteButton from '../blocks/delete-button';
import Subheader from 'material-ui/Subheader';
import _ from 'lodash';
import moment from 'moment';
import ExpenseTypeTag from '../blocks/expense-type-tag';

import {
    DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS,
    DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE
} from '../../actions/types';

const buttonStyle = {
   margin: '3px',
   display: 'inline-block',
}

class UnprocessedExpenseReports extends Component {

   constructor(props) {
      super(props);
      this.state = {
         forceUpdate: 0,
      };
   }

   componentDidMount() {
      this.props.dispatch(Actions.requestGetUnprocessedExpenseReports(Cookies.get('token')));
   }

   deleteExpenseReport(id) {
      const that = this;
      this.props.dispatch(Actions.requestDeleteUnsubmittedExpenseReport(id, Cookies.get('token')))
         .then(function(result){
            if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS){
               that.props.dispatch(Actions.newMessage('You have successfully deleted this expense report'));
            } else if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE){
              const { payload } = result,
                { message } = payload || {};
               that.props.dispatch(Actions.newError(message || 'Something west wrong.'));
            }
         });

   }

   incrementForceUpdate = ()=>{
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({forceUpdate});
   }

   getButtons = (expenseReport) => {
     const { distribution_id: distributionId } = expenseReport;
     const totalReimbursement = expenseReport.total_amount + expenseReport.user.profile.unreimbursed_amount;
      return [
         <ButtonLink 
            key={'view-button' + expenseReport.id}
            backgroundColor="#525252" 
            labelColor="#ffffff" 
            label="View"
            to={'/expense-report/' + expenseReport.id}                              
         />,
         <ProcessedModal 
            key={'process-button' + expenseReport.id}
            expenseReport={expenseReport}
            type={expenseReport.type}
            totalAmount={expenseReport.total_amount}
            unreimbursedAmount={expenseReport.user.profile.unreimbursed_amount}
            totalReimbursement={totalReimbursement}
            advanceRequestId={expenseReport.advance_request ? expenseReport.advance_request.id : null}
            style={{marginLeft: 3, display: 'inline-block'}}
            incrementForceUpdate={this.incrementForceUpdate}                    
         />,
         <DeleteButton
            key={'delete-button' + expenseReport.id}
            message="Delete this expense report?"
            label="Delete"
            backgroundColor="#e60000"
            labelColor="#ffffff" 
            style={buttonStyle} 
            contentStyle={{width: '40%'}}                                   
            onClick={()=> !!distributionId ? undefined : this.deleteExpenseReport(expenseReport.id)}
            noActions={!!distributionId}
            body={!distributionId
              ? undefined
              : <div>
                <p>
                  This expense report is referenced in this
                  <a href={`/admin/distribution-form/${distributionId}`}> distribution</a>.
                </p>
                <p>Please delete or modify the distribution before deleting this expense report.</p>
              </div>
            }
         />  
      ];
   }


   render() {
       const columns = [
           {key: 'id', label: 'ID', 'sortable': true},
           {key: 'name', label: 'Name', 'sortable': true},
           {key: 'missionary_number', label: 'Account #', 'sortable': true},
           {key: 'type', label: 'Expense Type', 'sortable': false},
           {key: 'usd_amount', label: 'Amount', 'sortable': false},
           {key: 'date_submitted', label: 'Date Submitted', 'sortable': false},
           {key: 'buttons', label: '', 'sortable': false},
           {}
       ];

       const unprocessedExpenseReports = _.get(this.props.expenseReports, 'unprocessedExpenseReports')
       const unprocessedExpenseReportsTable = _.keys(unprocessedExpenseReports).map( key => {
            return {
               id: unprocessedExpenseReports[key].id,
               name: unprocessedExpenseReports[key].user.name,
               missionary_number: unprocessedExpenseReports[key].user.profile ? unprocessedExpenseReports[key].user.profile.missionary_number : null,
               type: <ExpenseTypeTag type={unprocessedExpenseReports[key].type}/>,
               usd_amount: unprocessedExpenseReports[key].total_amount ? '$' + unprocessedExpenseReports[key].total_amount.toFixed(2) : null,
               date_submitted: unprocessedExpenseReports[key].date_submitted ? moment(unprocessedExpenseReports[key].date_submitted).format('MM/DD/YYYY') : null,
               processed: unprocessedExpenseReports[key].processed ? <Icon type="done"/> : <Icon type="clear"/>,
               buttons: this.getButtons(unprocessedExpenseReports[key]) 
            }
       });
       //sort the table by name
       unprocessedExpenseReportsTable.sort((a,b) => {
          let texta = a.name.toUpperCase();
          let textb = b.name.toUpperCase();
          return (texta < textb) ? -1 : (texta > textb) ? 1 : 0;
       });

       return (
           <div className="unprocessed-expense-reports">
               <div className="list-view table-view">
                    <Subheader className="table-style">Unprocessed Expense Reports</Subheader>
                    <Table data={unprocessedExpenseReportsTable} columns={columns} forceUpdate={this.state.forceUpdate}/>
               </div>
           </div>
       )
   }
}

export default connect(
   state => ({
       expenseReports: state.rootReducer.expenseReports
   })
)(UnprocessedExpenseReports);
