import {getActiveUsersRequest} from '../api/users';

import{
    GET_ACTIVE_USERS,
    GET_ACTIVE_USERS_SUCCESS,
    GET_ACTIVE_USERS_FAILURE
} from './types';


function getActiveUsers(){
    return{
        type: GET_ACTIVE_USERS
    }
}

function getActiveUsersSuccess(json){
    return{
        type: GET_ACTIVE_USERS_SUCCESS,
        payload: json
    }
}

function getActiveUsersFailure(error){
    return{
        type: GET_ACTIVE_USERS_FAILURE,
        payload: error
    }
}

export default function requestGetActiveUsers(token, filters = {}) {
    return dispatch => {
        dispatch(getActiveUsers());
        return getActiveUsersRequest(token, filters)
            .then((json) => {
                return dispatch(getActiveUsersSuccess(json));
            })
            .catch((error) => {
            console.log(error);
                return error.json().then((errors) => {
                    return dispatch(getActiveUsersFailure(errors));
                })
            });
    };
}
