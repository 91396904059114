import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import DistributionsTable from '../blocks/Distributions/distributions-table';
import {Tabs, Tab} from 'material-ui/Tabs';
import _ from 'lodash';

class AdminDistributions extends Component {
   constructor(props) {
      super(props);
      this.state = {
         value: 'approved'
      }
   }

   componentDidMount() {
      this.setState({value: this.props.params.status});
   }

   componentWillReceiveProps(nextProps) {
      if(!_.isUndefined(nextProps.params.status) && nextProps.params !== this.state.value) {
         this.setState({value: nextProps.params.status});
      }
   }


   handleChange = value => {
      this.setState({
         value: value,
      });
      browserHistory.push('/admin/distributions/' + value);
   };
   
   render() {
      return (
         <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            className="dist-table-tab"
         >
            <Tab label="Approved" value="approved" className="distribution-tab-individual">
               <DistributionsTable admin={true} userId={null} status="approved" selectable={false}/>
            </Tab>
            <Tab label="Pending" value="pending" className="distribution-tab-batch">
               <DistributionsTable admin={true} userId={null} status="pending" selectable={true}/>            
            </Tab> 
         </Tabs>
      )
   }
}

export default AdminDistributions;