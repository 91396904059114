import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';


export const getAllExpenseReportsRequest = (token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/expense-report',
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'api-token': token
            }
        }
    ).then(handleResponse);
};


export const getProcessedExpenseReportsRequest = (token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/expense-report/processed/1',
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'api-token': token
            }
        }
    ).then(handleResponse);
}


export const getAllExpenseReportsByUserRequest = (user_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/expense-report/user/' + user_id,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'api-token': token
            }
        }
    ).then(handleResponse);
};


export const postExpenseReportRequest = (formData, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/expense-report',
        {
            method: 'POST',
            headers: {
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const saveExpenseReportRequest = (expense_report_id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/save/expense-report/' + expense_report_id,
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const submitExpenseReportRequest = (expense_report_id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/submit/expense-report/' + expense_report_id,
       {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};


export const updateProcessRequest = (expense_report_id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/process-expense-report/' + expense_report_id,
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};



export const getExpenseReportRequest = (expense_report_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/expense-report/' + expense_report_id,
       {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'api-token': token
            }
        }
    ).then(handleResponse);
};


export const getUnprocessedExpenseReportsRequest = (token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/expense-report/processed/0',
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'api-token': token
            }
        }
    ).then(handleResponse);

};

export const deleteUnsubmittedExpenseReportRequest = (expense_report_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/expense-report/unsubmitted/' + expense_report_id,
        {
            method: 'DELETE',
            headers: {
                "api-token": token
            }
        }
    ).then(handleResponse);
};

export const deleteExpenseReportRequest = (expense_report_id, token) => {
    return fetch(
        process.env.REACT_APP_API_ROOT + '/expense-report/' + expense_report_id,
        {
            method: 'DELETE',
            headers: {
                "api-token": token
            }
        }
    ).then(handleResponse);
};
