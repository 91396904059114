import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Table from '../blocks/table';
import ButtonLink from '../blocks/button-link';
import RaisedButton from 'material-ui/RaisedButton';
import Subheader from 'material-ui/Subheader';
import ExpenseTypeTag from '../blocks/expense-type-tag';
import ProcessedModal from '../blocks/processed-modal';
import DeleteButton from '../blocks/delete-button';
import Dialog from 'material-ui/Dialog';
import moment from 'moment';
import {dateSubmittedTableSort} from '../../utilities/date-sort';
import {
   DELETE_EXPENSE_REPORT_SUCCESS,
   DELETE_EXPENSE_REPORT_FAILURE,
   DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS,
   DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE
} from '../../actions/types';

const buttonStyle = {
   marginRight: 3,
   display: 'inline-block',
   width: 92
};


class AdminDashboard extends Component {

   constructor(props) {
      super(props);
      // set our initial state
      this.state = {
         forceUpdate: 0,
         deleteExpenseReport: false,
         expenseReportId: ''
      };
   }

   componentDidMount() {
      this.props.dispatch(Actions.requestGetAllExpenseReports(Cookies.get('token')));
   }

   incrementForceUpdate = ()=>{
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({forceUpdate});
   }

   deleteExpenseReport(e) {
      e.preventDefault();
      const that = this;
      this.props.dispatch(Actions.requestDeleteExpenseReport(this.state.expenseReportId, Cookies.get('token')))
         .then(function(result){
            if (result.type === DELETE_EXPENSE_REPORT_SUCCESS){
               that.props.dispatch(Actions.newMessage('You have successfully deleted this expense report'));
               that.handleClose();
            } else if (result.type === DELETE_EXPENSE_REPORT_FAILURE){
               that.props.dispatch(Actions.newError('Something west wrong.'));
            }
         });
   }

   deleteUnprocessedExpenseReport(id) {
      const that = this;
      this.props.dispatch(Actions.requestDeleteUnsubmittedExpenseReport(id, Cookies.get('token')))
         .then(function(result){
            if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS){
               that.props.dispatch(Actions.newMessage('You have successfully deleted this expense report'));
               that.incrementForceUpdate();
            } else if (result.type === DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE){
               const { payload } = result,
                 { message } = payload || {};
               that.props.dispatch(Actions.newError(message || 'Something west wrong.'));
            }
         });

   }

   openDeleteDialogBox = (expenseReportId, event) => {
      event.preventDefault();
      this.setState({
         deleteExpenseReport: true,
         expenseReportId: expenseReportId
      });
   }

   handleClose = () => {
      this.setState({
         deleteExpenseReport: false,
         expenseReportId: ''
      });
   };


   getButtons = (expenseReport) => {
      const { distribution_id: distributionId } = expenseReport;
      let totalReimbursement = expenseReport.total_amount + expenseReport.user.profile.unreimbursed_amount;
      const processButton = <ProcessedModal 
                              key={'process-button' + expenseReport.id}
                              expenseReport={expenseReport}
                              type={expenseReport.type}
                              totalAmount={expenseReport.total_amount}
                              unreimbursedAmount={expenseReport.user.profile.unreimbursed_amount}
                              totalReimbursement={totalReimbursement}
                              advanceRequestId={expenseReport.advance_request ? expenseReport.advance_request.id : null}
                              style={buttonStyle}
                              incrementForceUpdate={this.incrementForceUpdate}                             
                           />

      const viewButton = <ButtonLink 
                           key={'view-button' + expenseReport.id}
                           backgroundColor="#525252" labelColor="#ffffff"
                           label="View"
                           to={'/expense-report/' + expenseReport.id} 
                           style={buttonStyle}                             
                        />
                        
      const archiveButton =  <RaisedButton
                                 key={'archive-button' + expenseReport.id}
                                 label='Archive'
                                 backgroundColor='#e60000'
                                 labelColor='#ffffff'                                     
                                 onClick={(e)=>this.openDeleteDialogBox(expenseReport.id, e)}
                                 style={buttonStyle} 
                              />

      const deleteButton =  <DeleteButton
                              key={'delete-button' + expenseReport.id}
                              message="Delete this expense report?"
                              label="Delete"
                              backgroundColor="#e60000"
                              labelColor="#ffffff" 
                              style={buttonStyle}                                                  
                              onClick={()=> !!distributionId ? undefined : this.deleteUnprocessedExpenseReport(expenseReport.id)}
                              noActions={!!distributionId}
                              body={!distributionId
                                ? undefined
                                : <div>
                                   <p>
                                      This expense report is referenced in this
                                      <a href={`/admin/distribution-form/${distributionId}`}> distribution</a>.
                                   </p>
                                   <p>Please delete or modify the distribution before deleting this expense report.</p>
                                </div>
                              }
                           /> 

      let buttons = [];
      buttons.push(viewButton);
      if(! expenseReport.processed) {
         buttons.push(processButton);
         buttons.push(deleteButton);
      }
      if(expenseReport.processed){
         buttons.push(archiveButton);
      }

      return (<div>{buttons}</div>);
   }


   render() {

      const actions_delete_expense_report = [
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#525252'}
            label="Cancel"
            onClick={this.handleClose} 
            style={{marginRight: 3}}                      
         />,
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#d40a0a'}
            label="Archive"
            onClick={(event) => this.deleteExpenseReport(event)}                       
         />
      ];

      const columns = [
         {key: 'id', label: 'ID', 'sortable': true},
         {key: 'name', label: 'Name', 'sortable': true},
         {key: 'missionary_number', label: 'Account #', 'sortable': true},
         {key: 'type', label: 'Expense Type', 'sortable': false},
         {key: 'total_amount', label: 'Amount', 'sortable': false},
         {key: 'payout', label: '$ Reimbursed', 'sortable': false},
         //{key: 'start_date', label: 'Start', 'sortable': false},
         //{key: 'end_date', label: 'End', 'sortable': false},
         {key: 'date_submitted', label: 'Submitted', 'sortable': false},
         {key: 'buttons', label: '', 'sortable': false},
         {}
      ];

      const expenseReportsTable = [];
      const expenseReports = this.props.expenseReports.expenseReports;
      for (const key in expenseReports) {
         const fix = expenseReports[key].user;
         // eslint-disable-next-line
         if (expenseReports[key].submitted == 1) {
               expenseReportsTable.push({
                  id: <div style={{width: 20}}>{expenseReports[key].id}</div>,
                  name: fix ? expenseReports[key].user.name : null,
                  missionary_number: fix.profile ? expenseReports[key].user.profile.missionary_number : null,
                  type: <ExpenseTypeTag type={expenseReports[key].type}/>,
                  total_amount: expenseReports[key].total_amount ? '$' + parseFloat(expenseReports[key].total_amount).toFixed(2) : null,
                  payout: expenseReports[key].payout ? '$' + parseFloat(expenseReports[key].payout).toFixed(2) : null,
                  //start_date: moment(expenseReports[key].start_date).format('MM/DD/YYYY'),
                  //end_date: moment(expenseReports[key].end_date).format('MM/DD/YYYY'),
                  date_submitted: moment(expenseReports[key].date_submitted).format('MM/DD/YYYY'),
                  buttons: this.getButtons(expenseReports[key])
            });
         }
      }
      expenseReportsTable.sort(dateSubmittedTableSort);

      return (
         <div className="admin-dashboard">
            <div className="list-view table-view">
               <div className="admin-dashboard-table">
                  <Subheader className="table-style">Expense Reports</Subheader>
                  <Table data={expenseReportsTable} columns={columns} forceUpdate={this.state.forceUpdate}/>
               </div>
            </div>
            <Dialog
               actions={actions_delete_expense_report}
               modal={true}
               open={this.state.deleteExpenseReport}
               contentStyle={{width: '90%'}}
               actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
            >
               <Subheader className="curved-style">Are you sure you want to archive this expense report?</Subheader>
            </Dialog>
         </div>
      )
   }
}

export default connect(
    state => ({
        expenseReports: state.rootReducer.expenseReports
    })
)(AdminDashboard);
