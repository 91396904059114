import {getAllExpenseReportsRequest} from '../api/expense-reports';

import{
    GET_ALL_EXPENSE_REPORTS,
    GET_ALL_EXPENSE_REPORTS_SUCCESS,
    GET_ALL_EXPENSE_REPORTS_FAILURE
} from './types';


function getAllExpenseReports(){
    return{
        type: GET_ALL_EXPENSE_REPORTS
    }
}

function getAllExpenseReportsSuccess(json){
    return{
        type: GET_ALL_EXPENSE_REPORTS_SUCCESS,
        payload: json
    }
}

function getAllExpenseReportsFailure(error){
    return{
        type: GET_ALL_EXPENSE_REPORTS_FAILURE,
        payload: error
    }
}


export default function requestGetAllExpenseReports(token) {
    return dispatch => {
        dispatch(getAllExpenseReports());
        return getAllExpenseReportsRequest(token)
            .then((json) => {
                return dispatch(getAllExpenseReportsSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getAllExpenseReportsFailure(errors));
                })
            });
    };
}
