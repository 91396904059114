import {mergeState, deleteFromState} from '11online-redux-helpers';

import{
    POST_USER,
    POST_USER_SUCCESS,
    POST_USER_FAILURE,
    GET_ACTIVE_USERS,
    GET_ACTIVE_USERS_SUCCESS,
    GET_ACTIVE_USERS_FAILURE,
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    PATCH_USER,
    PATCH_USER_SUCCESS,
    PATCH_USER_FAILURE,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE

} from '../actions/types';

export function reducer(state = {
    isFetching: false,
    users: {}
}, action = {type: ''}) {
    switch (action.type) {
        case POST_USER:
            return {
                ...state,
                isFetching: true
            };
        case POST_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                users: mergeState(state.users, action.payload)
            };
        case POST_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case GET_ACTIVE_USERS:
            return {
                ...state,
                isFetching: true
            };
        case GET_ACTIVE_USERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                users: {
                    ...(state.users || {}),
                    ...action.payload.reduce((acc, user) => ({ ...acc, [user.id]: user }), {}),
                }
            };
        case GET_ACTIVE_USERS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
                users: {}
            };
        case GET_USER:
            return {
                ...state,
                isFetching: true
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                users: {
                    ...(state.users || {}),
                    [action.payload.id]: action.payload,
                }
            };
        case GET_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
                users: {}
            };
        case PATCH_USER:
            return {
                ...state,
                isFetching: true
            };
        case PATCH_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                users: mergeState(state.users, action.payload)
            };
        case PATCH_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
                users: []
            };
        case DELETE_USER:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                users: deleteFromState(state.users, action.payload)
            };
        case DELETE_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };


        default:
            return state;
    }
}
