import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Table from '../blocks/table';
import AdvanceProcessModal from '../blocks/advance-process-modal';
import Subheader from 'material-ui/Subheader';
import moment from 'moment';
import _ from 'lodash';
import RaisedButton from 'material-ui/RaisedButton';
import DeleteButton from "../blocks/delete-button";
import {
   DELETE_ADVANCE_REQUEST_SUCCESS,
   DELETE_ADVANCE_REQUEST_FAILURE
} from '../../actions/types';

const buttonStyle = {
   width: '7em',
   margin: '2px',
}


class AdvanceExpenseReports extends Component {

   constructor(props) {
      super(props);
      this.state = {
         forceUpdate: 0,
      };
   }

   componentDidMount() {
      this.props.dispatch(Actions.requestGetAllAdvanceRequests(Cookies.get('token')));
   }

   incrementForceUpdate = () => {
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({ forceUpdate });
   }

   deleteAdvanceRequest(advance_request_id) {
      this.props.dispatch(Actions.requestDeleteAdvanceRequest(advance_request_id, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_ADVANCE_REQUEST_SUCCESS) {
               this.props.dispatch(Actions.newMessage('You have successfully deleted the advance request.'));
               this.props.dispatch(Actions.requestGetAllAdvanceRequests(Cookies.get('token')));
            } else if (result.type === DELETE_ADVANCE_REQUEST_FAILURE) {
               this.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   render() {
      const columns = [
         { key: 'id', label: 'ID#', 'sortable': true },
         { key: 'name', label: 'Name', 'sortable': true },
         { key: 'missionary_number', label: 'Account #', 'sortable': true },
         { key: 'amount_requested', label: 'Amount Requested', 'sortable': false },
         { key: 'date_submitted', label: 'Date Submitted', 'sortable': false },
         { key: 'status', label: 'Status', 'sortable': true },
         { key: 'buttons', label: '', 'sortable': false },
         {}
      ];
      const { advanceRequests = {} } = this.props.advanceRequests;
      const rows = Object.values(advanceRequests || {}).filter(x => x.status === 'requested').map(x => {
        const { distribution_id: distributionId, id, user, amount_requested, date_submitted, status } = x,
          { name: userName, profile } = user || {};
        return {
          id,
          name: userName || '',
          missionary_number: profile ? profile.missionary_number : null,
          amount_requested: '$' + amount_requested.toFixed(2),
          date_submitted: moment(date_submitted).format('MM/DD/YYYY'),
          status: _.upperFirst(status),
          buttons: [
              <AdvanceProcessModal
                key={ 'process' + id }
                advanceRequestSingle={ x }
                incrementForceUpdate={ this.incrementForceUpdate }
              />,
              <DeleteButton
                key={ 'delete' + id }
                label='Delete'
                labelColor='#ffffff'
                backgroundColor='#e60000'
                style={ buttonStyle }
                message="Delete this advance request?"
                noActions={!!distributionId}
                onClick={() => !!distributionId ? undefined : this.deleteAdvanceRequest(id)}
                body={!distributionId
                  ? undefined
                  : <div>
                    <p>
                      This advance request is referenced in this
                      <a href={`/admin/distribution-form/${distributionId}`}> distribution</a>.
                    </p>
                    <p>Please delete or modify the distribution before deleting this advance request.</p>
                  </div>
                }
              />
            ]
        };
      });

      return (
         <div className="unprocessed-advance-requests">
            <div className="list-view table-view">
               <Subheader className="table-style">Unprocessed Advance Requests</Subheader>
               <Table data={ rows } columns={ columns } forceUpdate={ this.state.forceUpdate } />
            </div>
         </div>
      )
   }
}

export default connect(
   state => ({
      advanceRequests: state.rootReducer.advanceRequests
   })
)(AdvanceExpenseReports);
