import React from 'react';
import {connect} from 'react-redux';
import Actions from '../../../actions';
import Cookies from 'cookies-js';
import Table from '../table';
import Subheader from 'material-ui/Subheader';
import DeleteButton from '../../blocks/delete-button';
import moment from 'moment';
import _ from 'lodash';
import {dateSortDesc} from '../../../utilities/date-sort';
import ButtonLink from '../../blocks/button-link';
import RaisedButton from 'material-ui/RaisedButton';
import DistributionsList from './distributions-list';

import {
   DELETE_DISTRIBUTION_SUCCESS,
   DELETE_DISTRIBUTION_FAILURE,
   POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS,
   POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE
} from '../../../actions/types';
import {withLoading} from "../with-loading";
import {formatCurrency} from "../../../utilities/currency-helpers";

const buttonStyle = {
   margin: '3px',
   display: 'inline-block',
}

class DistributionsTable extends React.Component {
   _isMounted = false;

   constructor(props) {
      super(props);
      this.state = {
         forceUpdate: 0,
         distributionIds: null,
      };
   };

   componentDidMount() {
      this._isMounted = true;  //for set state errors
      this.props.dispatch(Actions.requestGetAllDistributions(Cookies.get('token')));
   };

   componentWillUnmount() {
      this._isMounted = false;
   }

   deleteDistribution = (id) => {
      this.props.dispatch(Actions.requestDeleteDistribution(id, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_DISTRIBUTION_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Distribution Deleted'));
               this.incrementForceUpdate();
            }
            else if (result.type === DELETE_DISTRIBUTION_FAILURE) {
               this.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   approveDistributions = () => {
      //set form data
      let formData = new FormData();
      formData.append('distribution_ids', JSON.stringify(this.state.distributionIds));
      //make request
      this.props.dispatch(Actions.requestPostBatchDistributionsApprove(formData, Cookies.get('token')))
         .then(result => {
            if(result.type === POST_BATCH_DISTRIBUTIONS_APPROVE_SUCCESS) {
               this.props.dispatch(Actions.newMessage('Distributions Approved'));
               //reset state
               if(this._isMounted === true) {
                  this.setState({distributionIds: null});
               }
               this.incrementForceUpdate();
            } else if (result.type === POST_BATCH_DISTRIBUTIONS_APPROVE_FAILURE) {
               this.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   incrementForceUpdate = () => {
      const forceUpdate = this.state.forceUpdate + 1;
      if(this._isMounted === true) {
         this.setState({forceUpdate});
      }
   };

   onSelected = (result) => {
      if(this._isMounted === true) {
         //clear state array
         this.setState({distributionIds: null});
         if(result === 'all') {
            this.setState({selectAll: true});
            this.selectAll();
         } else if (result === 'none') {
            this.setState({distributionIds: null});
         } else {
            const newDistributionIds = result.map((row) => {
               return row.id;
            });
            this.setState({distributionIds: newDistributionIds});
         }
      }
   }

   selectAll = () => {
      //get pending distribtuions
      const distributions = _.values(this.props.distributions.distributions);
      const distributionIds = distributions
         .filter(dist => {
               return dist.status === 'pending';
            })
         .map(dist => {
            return dist.id;
         });
      if(this._isMounted === true) {
         this.setState({distributionIds: distributionIds});
      }
   }

   approveSelected = () => {
      if(this.state.distributionIds === null) {
         this.props.dispatch(Actions.newError('No distributions select.'));
      } else {
         this.approveDistributions();
      }
   }


   getButtons = (distribution) => {
      const viewRoute = this.props.admin ? `/admin/distribution-form/${distribution.id}` : `/distribution-form/${distribution.id}`;
      const viewButton =  <ButtonLink
                              label="View"
                              backgroundColor="#1E88E5"
                              labelColor="#ffffff"
                              style={buttonStyle}
                              to={viewRoute}
                           />
      const deleteButton =  <DeleteButton
                              message="Delete this distribution?"
                              label="Delete"
                              backgroundColor="#e60000"
                              labelColor="#ffffff"
                              style={buttonStyle}
                              contentStyle={{width: '40%'}}
                              onClick={()=>this.deleteDistribution(distribution.id)}
                           />

      const editButton = <ButtonLink
        label="Edit"
        backgroundColor="#1E88E5"
        labelColor="#ffffff"
        style={buttonStyle}
        to={`/admin/distribution-form/${distribution.id}`}
      />;
      return (
         <div>
            {this.props.status === 'approved' ? viewButton : ''}
            {this.props.status === 'pending' && this.props.admin  ? editButton : ''}
            {this.props.admin === true ? deleteButton : ''}
         </div>
      )
   }

   getAdminButtons = () => {
      if(this.props.status === 'pending' && this.props.admin ) {
         return (
            <div style={{textAlign: 'center'}}>
               <RaisedButton
                  labelColor="#ffffff"
                  backgroundColor={'#1E88E5'}
                  style={{margin: 5}}
                  label="Approve Selected"
                  onClick={() => this.approveSelected()}
               />
            </div>
         )
      }
   }

   tableCols = () => {
      const cols = [
         {key: 'id', label: 'ID', sortable: true},
         {key: 'name', label: 'Name', 'sortable': true},
         {key: 'to', label: 'Month End Date', sortable: true},
         {key: 'distribution', label: 'Distribution', 'sortable': false},
         {key: 'date', label: 'MAT Day', 'sortable': true},
         {key: 'buttons', label: '', 'sortable': false},
         {}
      ];
      if (this.props.admin) cols.splice(3, 0, ...[
         {key: 'endingBalance', label: 'Ending Balance', 'sortable': false},
         {key: 'payroll', label: 'Payroll', 'sortable': false},
         {key: 'expenses', label: 'Expenses', 'sortable': false},
      ]);
      return cols;
   };

   render() {
      let distributions = _.values(this.props.distributions.distributions);
      //if userId is not null pull out the entries for that userId
      if(this.props.userId !== null) {
         distributions = distributions.filter((dist) => {
            return dist.user_id === this.props.userId;
         })
      }
      //if requested status is not null filter for approved or pending
      if(this.props.status !== null){
         distributions = distributions.filter(dist => {
            return dist.status === this.props.status;
         });
      }

      const distributionsData = distributions.map( distribution => {
         return {
            id: distribution.id,
            name: distribution.user.name,
            //from: distribution.start_date,
            to: distribution.end_date,
            endingBalance: formatCurrency(distribution.ending_balance || 0),
            payroll: formatCurrency(distribution.total_payroll || 0),
            expenses: formatCurrency(distribution.total_expenses || 0),
            distribution: formatCurrency(distribution.total_funds_disbursed || 0),
            date: moment(distribution.created_at).format('MM/DD/YYYY'),
            buttons: this.getButtons(distribution),
         }
      }).sort(dateSortDesc);

      const title = this.props.userId !== null ? 'Distributions' : this.props.status !== null ? 'Distributions - Status: ' + this.props.status : '';
      return (
        <div className="list-view table-view">
           <Subheader className="table-style">
              {title}
           </Subheader>
           <div className="distributions-table">
              <Table
                data={distributionsData}
                columns={this.tableCols()}
                forceUpdate={this.state.forceUpdate}
                selectable={this.props.selectable}
                onSelected={this.props.selectable ? this.onSelected : ()=>{}}
                enableSelectAll={true}
                onRowSelection={this.onRowSelection}
              />
              {this.getAdminButtons()}
           </div>
           <div className="distributions-list">
              <DistributionsList data={distributionsData}/>
           </div>
        </div>
      );
   }
}//DistributionsTable

export default connect(
   state => ({
      distributions: state.rootReducer.distributions,
      users: state.rootReducer.users,
      loading: state.rootReducer.distributions.isFetching,
   })
)(withLoading(DistributionsTable));
