import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Table from '../blocks/table';
import Subheader from 'material-ui/Subheader';
import AdvanceProcessModal from '../blocks/advance-process-modal';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import moment from 'moment';
import ButtonLink from '../blocks/button-link';
import _ from 'lodash';
import {dateSubmittedTableSort} from '../../utilities/date-sort';
import {
   DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS,
   DELETE_CLOSED_ADVANCE_REQUEST_FAILURE
} from '../../actions/types';

const buttonStyle = {
   width: '7em',
   margin: '2px',
}

class AdvanceRequests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            forceUpdate: 0,
            deleteAdvanceRequest: false,
            advanceRequestId: ''
        };
    }

    componentDidMount() {
        this.props.dispatch(Actions.requestGetAllAdvanceRequests(Cookies.get('token')));
    }

    incrementForceUpdate = () => {
        const forceUpdate = this.state.forceUpdate + 1;
        this.setState({forceUpdate});
    }

   archiveAdvanceRequest = () => {
      this.props.dispatch(Actions.requestDeleteClosedAdvanceRequest(this.state.advanceRequestId, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_CLOSED_ADVANCE_REQUEST_SUCCESS) {
               this.props.dispatch(Actions.newMessage('You have successfully archived the advance request.'));
               this.props.dispatch(Actions.requestGetAllAdvanceRequests(Cookies.get('token')));
               this.handleClose();
            } else if (result.type === DELETE_CLOSED_ADVANCE_REQUEST_FAILURE) {
               this.props.dispatch(Actions.newError('Something went wrong.'));
            }
         });
   }

   openDeleteDialogBox = (advanceRequestId) => {
      this.setState({
         deleteAdvanceRequest: true,
         advanceRequestId: advanceRequestId
      });
   }

   handleClose = () => {
      this.setState({
         deleteAdvanceRequest: false,
         advanceRequestId: ''
      });
   };

	getButtons = (advanceRequest) => {

      const processButton = <AdvanceProcessModal
                           key={'process' + advanceRequest.id} 
                           advanceRequestSingle={advanceRequest}
                           incrementForceUpdate={this.incrementForceUpdate}
                        />;

      const viewButton = <ButtonLink
                        key={'view' + advanceRequest.id}
                        label="View"
                        labelColor="#ffffff"
                        backgroundColor='#525252'
                        style={buttonStyle}
                        to={'/expense-report/' + advanceRequest.expense_report_id}
                     />

      const archiveButton = <RaisedButton
                           key={'archive' + advanceRequest.id}
                           label='Archive'
                           labelColor='#ffffff'
                           backgroundColor='#e60000'
                           style={buttonStyle}
                           onClick={() => this.openDeleteDialogBox(advanceRequest.id)}
                        />

      let buttons = [];
      if (advanceRequest.amount_approved === null)
         buttons.push(processButton);
      if (advanceRequest.status === 'submitted' || advanceRequest.status === 'closed')
         buttons.push(viewButton)
      if (advanceRequest.status === 'closed')
         buttons.push(archiveButton)

      return buttons;
   }  

   render() {

      const columns = [
         {key: 'id', label: 'ID#', 'sortable': true},
         {key: 'name', label: 'Name', 'sortable': true},
         {key: 'missionary_number', label: 'Account #', 'sortable': true},
         //{key: 'date_submitted', label: 'Date Submitted', 'sortable': true},
         {key: 'mat_date', label: 'MAT', 'sortable': false},
         //{key: 'type', label: 'Type', 'sortable': false},
         {key: 'amount_requested', label: 'Amount Requested', 'sortable': false},
         {key: 'amount_approved', label: 'Amount Approved', 'sortable': false},
         {key: 'status', label: 'Status', 'sortable': true},
         {key: 'buttons', label: '', 'sortable': false},
         {},	
      ];

      const advanceRequests = this.props.advanceRequests.advanceRequests;
      const advanceRequestsTable = Object.keys(advanceRequests).map( key => {
      let buttons = this.getButtons(advanceRequests[key]);
      return {
            id: advanceRequests[key].id,
            name: advanceRequests[key].user.name,
            missionary_number: advanceRequests[key].user.profile ? advanceRequests[key].user.profile.missionary_number : null,
            //date_submitted: advanceRequests[key].date_submitted ? moment(advanceRequests[key].date_submitted).format('MM/DD/YYYY') : null,
            mat_date: moment(advanceRequests[key].mat_date).format('MM/DD/YYYY'),
            //type: _.upperFirst(advanceRequests[key].category.expense_category),
            amount_requested: '$' + advanceRequests[key].amount_requested.toFixed(2),
            amount_approved: advanceRequests[key].amount_approved ? '$' + (advanceRequests[key].amount_approved).toFixed(2) : null,
            status: _.upperFirst(advanceRequests[key].status),
            buttons: buttons
      }
      });
      advanceRequestsTable.sort(dateSubmittedTableSort);

      const actionsArchiveAdvanceRequest = [
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#525252'}
            label="Cancel"
            onClick={this.handleClose} 
            style={{marginRight: 3}}                      
         />,
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#d40a0a'}
            label="Archive"
            onClick={() => this.archiveAdvanceRequest()}                       
         />
      ];

      return (
         <div className="advance-requests">
            <div className="list-view table-view">
                  <Subheader className="table-style">Advance History</Subheader>
                  <Table data={advanceRequestsTable} columns={columns} forceUpdate={this.state.forceUpdate}/> 
            </div>
            <Dialog
               actions={actionsArchiveAdvanceRequest}
               modal={true}
               open={this.state.deleteAdvanceRequest}
               contentStyle={{width: '90%'}}
               actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
            >
               <Subheader className="curved-style">Are you sure you want to archive this advance request?</Subheader>
            </Dialog>
         </div>
      )
   }
}

export default connect(
   state => ({
       advanceRequests: state.rootReducer.advanceRequests
   })
)(AdvanceRequests);
