import {deleteFromState, mergeState} from '11online-redux-helpers';
import{
    GET_ALL_EXPENSE_REPORTS,
    GET_ALL_EXPENSE_REPORTS_SUCCESS,
    GET_ALL_EXPENSE_REPORTS_FAILURE,
    GET_ALL_EXPENSE_REPORTS_BY_USER,
    GET_ALL_EXPENSE_REPORTS_BY_USER_SUCCESS,
    GET_ALL_EXPENSE_REPORTS_BY_USER_FAILURE,
    POST_EXPENSE_REPORT,
    POST_EXPENSE_REPORT_SUCCESS,
    POST_EXPENSE_REPORT_FAILURE,
    DELETE_FILE_UPLOAD,
    DELETE_FILE_UPLOAD_SUCCESS,
    DELETE_FILE_UPLOAD_FAILURE,
    POST_FILE_UPLOAD,
    POST_FILE_UPLOAD_SUCCESS,
    POST_FILE_UPLOAD_FAILURE,
    GET_EXPENSE_REPORT,
    GET_EXPENSE_REPORT_SUCCESS,
    GET_EXPENSE_REPORT_FAILURE,
    SAVE_EXPENSE_REPORT,
    SAVE_EXPENSE_REPORT_SUCCESS,
    SAVE_EXPENSE_REPORT_FAILURE,
    SUBMIT_EXPENSE_REPORT,
    SUBMIT_EXPENSE_REPORT_SUCCESS,
    SUBMIT_EXPENSE_REPORT_FAILURE,
    PATCH_PROCESS,
    PATCH_PROCESS_SUCCESS,
    PATCH_PROCESS_FAILURE,
    GET_PROCESSED_EXPENSE_REPORTS,
    GET_PROCESSED_EXPENSE_REPORTS_SUCCESS,
    GET_PROCESSED_EXPENSE_REPORTS_FAILURE,
    GET_UNPROCESSED_EXPENSE_REPORTS,
    GET_UNPROCESSED_EXPENSE_REPORTS_SUCCESS,
    GET_UNPROCESSED_EXPENSE_REPORTS_FAILURE,
    POST_EXPENSE,
    POST_EXPENSE_SUCCESS,
    POST_EXPENSE_FAILURE,
    PATCH_EXPENSE,
    PATCH_EXPENSE_SUCCESS,
    PATCH_EXPENSE_FAILURE,
    DELETE_EXPENSE,
    DELETE_EXPENSE_SUCCESS,
    DELETE_EXPENSE_FAILURE,
    GET_EXPENSE,
    GET_EXPENSE_SUCCESS,
    GET_EXPENSE_FAILURE,
    POST_MILEAGE,
    POST_MILEAGE_SUCCESS,
    POST_MILEAGE_FAILURE,
    PATCH_MILEAGE,
    PATCH_MILEAGE_SUCCESS,
    PATCH_MILEAGE_FAILURE,
    DELETE_MILEAGE,
    DELETE_MILEAGE_SUCCESS,
    DELETE_MILEAGE_FAILURE,
    GET_MILEAGE,
    GET_MILEAGE_SUCCESS,
    GET_MILEAGE_FAILURE,
    DELETE_UNSUBMITTED_EXPENSE_REPORT,
    DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS,
    DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE,
    DELETE_EXPENSE_REPORT,
    DELETE_EXPENSE_REPORT_SUCCESS,
    DELETE_EXPENSE_REPORT_FAILURE

} from '../actions/types';

function mergeStateOverride(state, payload) {
    if (!payload) return state || {};
    if (Array.isArray(payload)) return {
        ...(state || {}),
        ...payload.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}),
    };
    return {
        ...(state || {}),
        [payload.id]: payload,
    };
}

export function reducer(state = {
    isFetching: false,
    user: {},
    expenseReports: {},
    processedExpenseReports: {},
    unprocessedExpenseReports: {},
    expenseReportsByUser: {}
}, action = {type: ''}) {
    switch (action.type) {
        case GET_ALL_EXPENSE_REPORTS:
            return {
                ...state,
                isFetching: true
            };
        case GET_ALL_EXPENSE_REPORTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeState(state.expenseReports, action.payload)
            };
        case GET_ALL_EXPENSE_REPORTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case GET_ALL_EXPENSE_REPORTS_BY_USER:
            return {
                ...state,
                isFetching: true
            };
        case GET_ALL_EXPENSE_REPORTS_BY_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReportsByUser: mergeState({}, action.payload)
            };
        case GET_ALL_EXPENSE_REPORTS_BY_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };

        case GET_PROCESSED_EXPENSE_REPORTS:
            return {
                ...state,
                isFetching: true
            };
        case GET_PROCESSED_EXPENSE_REPORTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                processedExpenseReports: mergeState(state.processedExpenseReports, action.payload)
            };
        case GET_PROCESSED_EXPENSE_REPORTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case GET_UNPROCESSED_EXPENSE_REPORTS:
            return {
                ...state,
                isFetching: true
            };
        case GET_UNPROCESSED_EXPENSE_REPORTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                unprocessedExpenseReports: mergeState(state.unprocessedExpenseReports, action.payload)
            };
        case GET_UNPROCESSED_EXPENSE_REPORTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };

        case PATCH_PROCESS:
            return {
                ...state,
                isFetching: true
            };
        case PATCH_PROCESS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                processedExpenseReports: mergeState(state.processedExpenseReports, action.payload),
                unprocessedExpenseReports: deleteFromState(state.unprocessedExpenseReports, action.payload),
                expenseReports: mergeState(state.expenseReports, action.payload)
            };
        case PATCH_PROCESS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case POST_EXPENSE_REPORT:
            return {
                ...state,
                isFetching: true
            };
        case POST_EXPENSE_REPORT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeStateOverride(state.expenseReports, action.payload),
                expenseReportsByUser: mergeStateOverride(state.expenseReportsByUser, action.payload)
            };

        case POST_EXPENSE_REPORT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case GET_EXPENSE_REPORT:
            return {
                ...state,
                isFetching: true
            };
        case GET_EXPENSE_REPORT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: {
                    ...(state.expenseReports || {}),
                    [action.payload.id]: action.payload,
                }
            };
        case GET_EXPENSE_REPORT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case SAVE_EXPENSE_REPORT:
            return {
                ...state,
                isFetching: true
            };
        case SAVE_EXPENSE_REPORT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeStateOverride(state.expenseReports, action.payload),
                expenseReportsByUser: mergeStateOverride(state.expenseReportsByUser, action.payload)
            };
        case SAVE_EXPENSE_REPORT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
            };
        case SUBMIT_EXPENSE_REPORT:
            return {
                ...state,
                isFetching: true
            };

        case SUBMIT_EXPENSE_REPORT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeStateOverride(state.expenseReports, action.payload),
                expenseReportsByUser: mergeStateOverride(state.expenseReportsByUser, action.payload)
            };
        case SUBMIT_EXPENSE_REPORT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
            };
        case POST_MILEAGE:
            return {
                ...state,
                isFetching: true
            };
        case POST_MILEAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeState(state.expenseReports, action.payload)
            };
        case POST_MILEAGE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case PATCH_MILEAGE:
            return {
                ...state,
                isFetching: true
            };
        case PATCH_MILEAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeState(state.expenseReports, action.payload)
            };
        case PATCH_MILEAGE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
            };
        case DELETE_MILEAGE:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_MILEAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeState(state.expenseReports, action.payload)
            };
        case DELETE_MILEAGE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case GET_MILEAGE:
            return {
                ...state,
                isFetching: true
            };
        case GET_MILEAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeState(state.expenseReports, action.payload)
            };
        case GET_MILEAGE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case POST_EXPENSE:
            return {
                ...state,
                isFetching: true
            };
        case POST_EXPENSE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeStateOverride(state.expenseReports, action.payload)
            };
        case POST_EXPENSE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case PATCH_EXPENSE:
            return {
                ...state,
                isFetching: true
            };
        case PATCH_EXPENSE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeState(state.expenseReports, action.payload)
            };
        case PATCH_EXPENSE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false,
            };
        case DELETE_EXPENSE:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_EXPENSE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeState(state.expenseReports, action.payload)

            };
        case DELETE_EXPENSE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case GET_EXPENSE:
            return {
                ...state,
                isFetching: true
            };
        case GET_EXPENSE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: mergeState(state.expenseReports, action.payload)
            };
        case GET_EXPENSE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case POST_FILE_UPLOAD:
            return {
                ...state,
                isFetching: true
            };
        case POST_FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: {
                    ...(state.expenseReports || {}),
                    [action.payload.id]: action.payload,
                }
            };
        case POST_FILE_UPLOAD_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case DELETE_FILE_UPLOAD:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: {
                    ...(state.expenseReports || {}),
                    [action.payload.id]: action.payload,
                }
            };
        case DELETE_FILE_UPLOAD_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };
        case DELETE_UNSUBMITTED_EXPENSE_REPORT:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_UNSUBMITTED_EXPENSE_REPORT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: deleteFromState(state.expenseReports, action.payload),
                processedExpenseReports: deleteFromState(state.processedExpenseReports, action.payload),
                unprocessedExpenseReports: deleteFromState(state.unprocessedExpenseReports, action.payload),
                expenseReportsByUser: deleteFromState(state.expenseReportsByUser, action.payload)
            };
        case DELETE_UNSUBMITTED_EXPENSE_REPORT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };

        case DELETE_EXPENSE_REPORT:
            return {
                ...state,
                isFetching: true
            };
        case DELETE_EXPENSE_REPORT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: [],
                expenseReports: deleteFromState(state.expenseReports, action.payload),
                processedExpenseReports: deleteFromState(state.processedExpenseReports, action.payload),
                unprocessedExpenseReports: deleteFromState(state.unprocessedExpenseReports, action.payload),
            };
        case DELETE_EXPENSE_REPORT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            };

        default:
            return state;
    }
}
