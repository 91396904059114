import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import _ from 'lodash';
import Table from '../blocks/table';
import ButtonLink from '../blocks/button-link';
import Subheader from 'material-ui/Subheader';
import RaisedButton from 'material-ui/RaisedButton';
import {DELETE_USER_SUCCESS, DELETE_USER_FAILURE} from '../../actions/types';

class AdminActiveUsers extends Component {


   componentDidMount() {
      this.props.dispatch(Actions.requestGetActiveUsers(Cookies.get('token')));
   }

   archiveUser(id, e) {
      e.preventDefault();
      const that = this;
      this.props.dispatch(Actions.requestDeleteUser(id, Cookies.get('token')))
         .then(function(result){
               if (result.type === DELETE_USER_SUCCESS){
                  that.props.dispatch(Actions.newMessage('You have successfully archived this user.'));
               } else if (result.type === DELETE_USER_FAILURE){
                  that.props.dispatch(Actions.newError('Something went wrong.'));
               }
         });
   }

   getButtons = (user) => {
      return [
         <ButtonLink
            key={'view-user' + user.id} 
            backgroundColor="#525252"
            labelColor="#ffffff"
            label="View"
            style={{marginRight: 3, marginLeft: 75}}
            to={'/admin/user/' + user.id  + '/profile'}               
         />,
         <RaisedButton
            key={'archive-user' + user.Id}
            label='Archive'
            backgroundColor='#e60000'
            labelColor='#ffffff'
            buttonStyle={this.props.buttonStyle}
            style={this.props.style}
            labelStyle={this.props.labelStyle}
            onClick={(e)=>this.archiveUser(user.id, e)}
         />
      ]; 
   }

   render() {
      const columns = [
          {key: 'name', label: 'Name', 'sortable': true},
          {key: 'role', label: 'Role', 'sortable': true},
          {key: 'email', label: 'Email', 'sortable': false},
          {key: 'missionary_number', label: 'Account', 'sortable': true},
          {key: 'profile_unreimbursed_amount', label: 'Unreimbursed Amount', 'sortable': true},
          {key: 'buttons', label: '', 'sortable': false},
          {}
      ];

      const usersTable = [];
      const users = this.props.users.users;     
      for (const key in users) {
            usersTable.push(
               {
                  name: users[key].name,
                  role:  _.upperFirst(users[key].role),
                  email: users[key].email,
                  missionary_number: users[key].profile ? users[key].profile.missionary_number : null,
                  profile_unreimbursed_amount: users[key].profile ? users[key].profile.unreimbursed_amount ? '$' + users[key].profile.unreimbursed_amount.toFixed(2) : null : null,
                  buttons: this.getButtons(users[key])               
               }
            )
      }

        return (
            <div className="users-table">
              <ButtonLink backgroundColor="#525252" labelColor="#ffffff" label="Archived Users" to={'/admin/users/archived'} />
              <div className="list-view table-view">
                  <Subheader className="table-style">Active Users</Subheader>
                  <Table data={usersTable} columns={columns}/>
              </div>
          </div>
      )
  }
}

export default connect(
    state => ({
        users: state.rootReducer.users
    })
)(AdminActiveUsers);
