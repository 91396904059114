import fetch from 'isomorphic-fetch';
import {handleResponse} from '11online-fetch-helpers';

export const denariUploadRequest = (formData, token) => {
   return fetch(
      process.env.REACT_APP_API_ROOT + '/donation/denari-upload', {
         method: 'POST',
         headers: {
            'api-token': token
         },
         body: formData
      }
   ).then(handleResponse);
};

export const getAllDonationsRequest = (token) => {
   return fetch(
       process.env.REACT_APP_API_ROOT + '/donation',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
};

export const patchDonationRequest = (id, formData, token) => {
    formData.append("_method", "PATCH");
    return fetch(
        process.env.REACT_APP_API_ROOT + '/donation/' + id,
        {
            method: 'POST',
            headers:{
                'api-token': token
            },
            body: formData
        }
    ).then(handleResponse);
};

export const deleteDonationRequest = (id, token) => {
   return fetch(
      process.env.REACT_APP_API_ROOT + '/donation/' + id, {
         method: 'DELETE',
         headers: {
            "api-token": token
         }
      }
   ).then(handleResponse);
};

export const getRecentDonationUploadNamesRequest = (token) => {
   return fetch (
      process.env.REACT_APP_API_ROOT + '/recentUploadNames',
       {
           method: 'GET',
           headers: {
               'Content-Type': 'application/json',
               'api-token': token
           }
       }
   ).then(handleResponse);
}