//Login and passwords
import login from './login';
import requestPasswordReset from './reset-password';
import requestUpdatePassword from './update-password';
import loginWithToken from './login-with-token';
import logout from './logout';
import requestResetPasswordEmail from './reset-password-email';

//Messages
import newMessage from './new-message';
import newError from './new-error';
import clearMessage from './clear-message';

//Users
import requestPostUser from './post-user';
import requestGetUser from './get-user';
import requestPatchUser from './patch-user';
import requestGetActiveUsers from './get-active-users';
import requestGetArchivedUsers from './get-archived-users';
import requestDeleteUser from './delete-user';
import requestRestoreArchivedUser from './restore-archived-user.js';

//Expense Reports
import requestGetAllExpenseReports from './get-all-expense-reports';
import requestGetAllExpenseReportsByUser from './get-all-expense-reports-by-user';
import requestPostExpenseReport from './post-expense-report';
import requestGetExpenseReport from './get-expense-report';
import requestSaveExpenseReport from './save-expense-report';
import requestSubmitExpenseReport from './submit-expense-report';
import requestGetProcessedExpenseReports from './get-processed-expense-reports';
import requestGetUnprocessedExpenseReports from './get-unprocessed-expense-reports';
import requestDeleteUnsubmittedExpenseReport from './delete-unsubmitted-expense-report';
import requestDeleteExpenseReport from './delete-expense-report';

//Expenses
import requestPostExpense from './post-expense';
import requestPatchExpense from './patch-expense';
import requestDeleteExpense from './delete-expense';
import requestGetExpense from './get-expense';

//File Uploads
import requestPostFileUpload from './post-file-upload';
import requestDeleteFileUpload from './delete-file-upload';

//Mileage
import requestPostMileage from './post-mileage';
import requestPatchMileage from './patch-mileage';
import requestDeleteMileage from './delete-mileage';
import requestGetMileage from './get-mileage';

//ADVANCE REQUESTS
import requestGetAllAdvanceRequests from './get-all-advance-requests';
import requestPatchAdvanceRequest from './patch-advance-request';
import requestGetAllAdvanceRequestsByUser from './get-all-advance-requests-by-user';
import requestPostAdvanceRequest from './post-advance-request';
import requestUpdateProcess from './update-process';
import requestDeleteAdvanceRequest from './delete-advance-request'
import requestDeleteClosedAdvanceRequest from './delete-closed-advance-request'

//Settings
import requestGetAllSettings from './get-all-settings';
import requestPatchSettings from './patch-settings';

//Categories
import requestGetAllCategories from './get-all-categories';
import requestPostCategory from './post-category';
import requestDeleteCategory from './delete-category';
import requestPatchCategory from './patch-category';

//Donations
import requestPostDenariUpload from './post-denari-upload';
import requestGetAllDonations from './get-all-donations';
import requestPatchDonation from './patch-donation';
import requestDeleteDonation from './delete-donation';

//Hold Accounts
import requestGetAllHoldAccounts from './get-all-hold-accounts';
import requestPostHoldAccount from './post-hold-account';
import requestPatchHoldAccount from './patch-hold-account';
import requestDeleteHoldAccount from './delete-hold-account';
import requestArchiveHoldAccount from "./archive-hold-account";

//Distributions
import requestGetAllDistributions from './get-all-distributions';
import requestPostDistribution from './post-distribution';
import requestPatchDistribution from './patch-distribution';
import requestDeleteDistribution from './delete-distribution';
import requestPostBatchDistributions from './post-batch-distributions'
import requestPostBatchDistributionsApprove from './post-batch-distributions-approve'
import requestGetDistribution from './get-distribution';


const actions = {
   login,
   requestPasswordReset,
   requestUpdatePassword,
   loginWithToken,
   logout,
   requestResetPasswordEmail,
   newMessage,
   newError,
   clearMessage,

   /*POST*/
   requestPostUser,
   requestPostExpenseReport,
   requestPostExpense,
   requestPostMileage,
   requestPostFileUpload,
   requestPostAdvanceRequest,
   requestPostCategory,
   requestPostDenariUpload,
   requestPostHoldAccount,
   requestPostDistribution,
   requestPostBatchDistributions,
   requestPostBatchDistributionsApprove,

   /*GET*/
   requestGetAllExpenseReports,
   requestGetProcessedExpenseReports,
   requestGetActiveUsers,
   requestGetArchivedUsers,
   requestGetAllExpenseReportsByUser,
   requestGetUser,
   requestGetExpenseReport,
   requestGetMileage,
   requestGetExpense,
   requestGetAllAdvanceRequests,
   requestGetAllAdvanceRequestsByUser,
   requestGetAllSettings,
   requestGetAllCategories,
   requestGetAllDonations,
   requestGetAllHoldAccounts,
   requestGetAllDistributions,
   requestGetDistribution,
   requestArchiveHoldAccount,

   /*Patch*/
   requestPatchUser,
   requestPatchAdvanceRequest,
   requestPatchSettings,
   requestPatchCategory,
   requestSaveExpenseReport,
   requestSubmitExpenseReport,
   requestPatchExpense,
   requestPatchMileage,
   requestUpdateProcess,
   requestGetUnprocessedExpenseReports,
   requestRestoreArchivedUser,
   requestPatchDonation,
   requestPatchHoldAccount,
   requestPatchDistribution,

   /*DELETE*/
   requestDeleteMileage,
   requestDeleteExpense,
   requestDeleteExpenseReport,
   requestDeleteFileUpload,
   requestDeleteUnsubmittedExpenseReport,
   requestDeleteCategory,
   requestDeleteUser,
   requestDeleteAdvanceRequest,
   requestDeleteHoldAccount,
   requestDeleteDonation,
   requestDeleteDistribution,
   requestDeleteClosedAdvanceRequest
};

export default actions;
