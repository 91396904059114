import {getAllDonationsRequest} from '../api/donations';

import{
   GET_ALL_DONATIONS,
   GET_ALL_DONATIONS_SUCCESS,
   GET_ALL_DONATIONS_FAILURE
} from './types';


function getAllDonations(){
    return{
        type: GET_ALL_DONATIONS
    }
}

function getAllDonationsSuccess(json){
    return{
        type: GET_ALL_DONATIONS_SUCCESS,
        payload: json
    }
}

function getAllDonationsFailure(error){
    return{
        type: GET_ALL_DONATIONS_FAILURE,
        payload: error
    }
}

export default function requestGetAllDonations(token) {
    return dispatch => {
        dispatch(getAllDonations());
        return getAllDonationsRequest(token)
            .then((json) => {
                return dispatch(getAllDonationsSuccess(json));
            })
            .catch((error) => {
                return error.json().then((errors) => {
                    return dispatch(getAllDonationsFailure(errors));
                })
            });
    };
}
