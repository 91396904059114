import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import _ from 'lodash';
import Table from '../blocks/table';
import ButtonLink from '../blocks/button-link';
import Subheader from 'material-ui/Subheader';
import RaisedButton from 'material-ui/RaisedButton';
import {RESTORE_ARCHIVED_USER_SUCCESS, RESTORE_ARCHIVED_USER_FAILURE} from '../../actions/types';
import {DELETE_USER_SUCCESS, DELETE_USER_FAILURE} from '../../actions/types';

class AdminArchivedUsers extends Component {


    componentDidMount() {
        this.props.dispatch(Actions.requestGetArchivedUsers(Cookies.get('token')));
    }

    restoreUser(id, e) {
        e.preventDefault();
        const that = this;
        this.props.dispatch(Actions.requestRestoreArchivedUser(id, Cookies.get('token')))
            .then(function(result){
                if (result.type === RESTORE_ARCHIVED_USER_SUCCESS){
                    that.props.dispatch(Actions.newMessage('You have successfully restored this user.'));
                } else if (result.type === RESTORE_ARCHIVED_USER_FAILURE){
                    that.props.dispatch(Actions.newError('Something went wrong.'));
                }
            });
    }

    deleteUser(id, e) {
        e.preventDefault();
        const that = this;
        this.props.dispatch(Actions.requestDeleteUser(id, Cookies.get('token')))
            .then(function(result){
                if (result.type === DELETE_USER_SUCCESS){
                    that.props.dispatch(Actions.newMessage('You have successfully deleted this user.'));
                } else if (result.type === DELETE_USER_FAILURE){
                    that.props.dispatch(Actions.newError('Something went wrong.'));
                }
            });
    }

  render() {
      const columns = [
          {key: 'name', label: 'Name', 'sortable': true},
          {key: 'role', label: 'Role', 'sortable': true},
          {key: 'email', label: 'Email', 'sortable': false},
          {key: 'missionary_number', label: 'Account', 'sortable': true},
          {key: 'restore', label: 'Restore', 'sortable': false},
          {key: 'delete', label: 'Delete', 'sortable': false},
      ];

      const usersTable = [];
      const users = this.props.users.users;     
      for (const key in users) {
              usersTable.push(
                  {
                      name: users[key].name,
                      role:  _.upperFirst(users[key].role),
                      email: users[key].email,
                      missionary_number: users[key].profile ? users[key].profile.missionary_number : null,
                      restore: (<RaisedButton
                                        label='Restore'
                                        backgroundColor='#1E88E5'
                                        labelColor='#ffffff'
                                        buttonStyle={this.props.buttonStyle}
                                        style={this.props.style}
                                        labelStyle={this.props.labelStyle}
                                        onClick={(e)=>this.restoreUser(users[key].id, e)}
                                        />),
                      delete: (<RaisedButton
                                        label='Delete Permanently'
                                        backgroundColor='#e60000'
                                        labelColor='#ffffff'
                                        buttonStyle={this.props.buttonStyle}
                                        style={this.props.style}
                                        labelStyle={this.props.labelStyle}
                                        onClick={(e)=>this.deleteUser(users[key].id, e)}
                                        />),
                  }
              )
      }

        return (
            <div className="admin-dashboard">
              <ButtonLink backgroundColor="#525252" labelColor="#ffffff" label="Active Users" to={'/admin/users'} />
              <div className="list-view table-view">
                  <Subheader className="table-style">All Archived Users</Subheader>
                  <Table data={usersTable} columns={columns}/>
              </div>
          </div>
      )
  }
}

export default connect(
    state => ({
        users: state.rootReducer.archivedUsers
    })
)(AdminArchivedUsers);
