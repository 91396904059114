import React from 'react';
import {connect} from 'react-redux';
import Actions from '../../../actions';
import Cookies from 'cookies-js';
import Subheader from 'material-ui/Subheader';
import RaisedButton from 'material-ui/RaisedButton';
import DeleteButton from '../../blocks/delete-button';
import moment from 'moment';
import _ from 'lodash';
import {dateSortDesc} from '../../../utilities/date-sort';
import DonationsDialog from '../../blocks/donations/donations-dialog';
import DonationsList from './donations-list';
import DataTables from 'material-ui-datatables';

import {
   PATCH_DONATION_SUCCESS,
   PATCH_DONATION_FAILURE,
   DELETE_DONATION_SUCCESS,
   DELETE_DONATION_FAILURE
} from '../../../actions/types';


const buttonStyle = {
   margin: '3px',
   display: 'inline-block',
}

class DonationsTable extends React.Component {
   state = {
      open: false,
      amount: '',
      count: '',
      donationId: ''
   };

   componentDidMount() {
      if(_.isEmpty(this.props.donations))
         this.props.dispatch(Actions.requestGetAllDonations(Cookies.get('token')));
   };

   updateDonation = () => {
      const form = document.getElementById('donations-form');
      const formData = new FormData(form);
      this.props.dispatch(Actions.requestPatchDonation(this.state.donationId, formData, Cookies.get('token')))
         .then((result) => {
            if (result.type === PATCH_DONATION_SUCCESS)
               this.props.dispatch(Actions.newMessage('Donation Updated'));
            else if (result.type === PATCH_DONATION_FAILURE)
               this.props.dispatch(Actions.newError(result.payload.message));
         })
      this.handleClose();
   }

   deleteDonation = (id) => {
      this.props.dispatch(Actions.requestDeleteDonation(id, Cookies.get('token')))
         .then((result) => {
            if (result.type === DELETE_DONATION_SUCCESS)
               this.props.dispatch(Actions.newMessage('Donation Deleted'));
            else if (result.type === DELETE_DONATION_FAILURE)
               this.props.dispatch(Actions.newError('Something went wrong.'));
         });
   }

   handleOpen = (donation) => this.setState({
      open: true,
      amount: donation.amount,
      count: donation.count,
      donationId: donation.id
   });

   handleClose = () => this.setState({
      open: false,
      amount: '',
      count: '',
      donationId: ''
   });

   onChange = (key, value) => this.setState({[key]: value});

   getButtons = (donation) => {
      if (!this.props.admin) return;
      return (
        <div>
           <RaisedButton
             label="Edit"
             className="edit-donation"
             backgroundColor="#1E88E5"
             labelColor="#ffffff"
             style={buttonStyle}
             onClick={() => this.handleOpen(donation)}
           />
           <DeleteButton
             message="Delete this donation?"
             label="Delete"
             backgroundColor="#e60000"
             labelColor="#ffffff"
             style={buttonStyle}
             contentStyle={{width: '40%'}}
             onClick={()=>this.deleteDonation(donation.id)}
           />
        </div>
      )
   }

   getRows = () => {
      let donations = this.props.donations || [];
      if (!!this.props.userId)
         donations = donations.filter(x => x.user_id === this.props.userId);
      return donations.map(donation => ({
         id: donation.id,
         name: 'user' in donation ? donation.user.name : '',
         holdAccount: donation.hold_account ? donation.hold_account.fund_name : '',
         amount: '$' + donation.amount,
         count: donation.count,
         date: moment(donation.created_at).format('MM/DD/YYYY'),
         buttons: this.getButtons(donation),
         uploadName: donation.upload_name,
      })).sort(dateSortDesc);
   }

   render() {
      const columns = [
         {key: 'id', label: 'ID'},
         {key: 'name', label: 'Name'},
         {key: 'holdAccount', label: 'Hold Fund'},
         {key: 'amount', label: 'Amount', 'sortable': false},
         {key: 'count', label: 'Count', 'sortable': false},
         {key: 'uploadName', label: 'Upload Name (Month)', sortable: false},
         {key: 'buttons', label: '', 'sortable': false},
         {}
      ];

      const data = this.getRows();

      return (
         <div className="list-view table-view">
            <Subheader className="table-style">Donations</Subheader>
            <div className="donation-table">
               <DataTables
                 data={data}
                 columns={columns}
               />
            </div>
            <div className="donation-list">
               <DonationsList data={data}/>
            </div>
            <DonationsDialog
               open={this.state.open}
               amount={this.state.amount}
               count={this.state.count}
               donationId={this.state.donationId}
               handleClose={this.handleClose}
               onChange={this.onChange}
               onClick={this.updateDonation}
            />	
         </div>
      )
   }
}

export default connect(
   state => ({
       donations: Object.values(state.rootReducer.donations.donations || {}),
   })
)(DonationsTable);
