import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import Cookies from 'cookies-js';
import Table from '../blocks/table';
import _ from 'lodash';
import ButtonLink from '../blocks/button-link';
import ExpenseTypeTag from '../blocks/expense-type-tag';
import Subheader from 'material-ui/Subheader';
import Dialog from 'material-ui/Dialog';
import moment from 'moment';
import {dateSubmittedTableSort} from '../../utilities/date-sort';
import RaisedButton from 'material-ui/RaisedButton';
import {
   DELETE_EXPENSE_REPORT_SUCCESS,
   DELETE_EXPENSE_REPORT_FAILURE
} from '../../actions/types';

const buttonStyle = {
   marginRight: 3,
   display: 'inline-block',
   width: 92
};

class ProcessedExpenseReports extends Component {

   constructor(props) {
      super(props);
      // set our initial state
      this.state = {
         deleteExpenseReport: false,
         expenseReportId: '',
         forceUpdate: 0
      };
   }

   componentDidMount() {
      this.props.dispatch(Actions.requestGetProcessedExpenseReports(Cookies.get('token')));
   }

   deleteExpenseReport(e) {
      e.preventDefault();
      const that = this;
      this.props.dispatch(Actions.requestDeleteExpenseReport(this.state.expenseReportId, Cookies.get('token')))
         .then(function(result){
            if(result.type === DELETE_EXPENSE_REPORT_SUCCESS) {
               that.props.dispatch(Actions.newMessage('You have successfully deleted this expense report'));
               that.incrementForceUpdate();
               that.handleClose();
            } else if (result.type === DELETE_EXPENSE_REPORT_FAILURE) {
               that.props.dispatch(Actions.newError('Something west wrong.'));
            }
         });
   }

   openDeleteDialogBox = (expenseReportId, event) => {
      event.preventDefault();
      this.setState({
         deleteExpenseReport: true,
         expenseReportId: expenseReportId
      });
   }

   handleClose = () => {
      this.setState({
         deleteExpenseReport: false,
         expenseReportId: ''
      });
   };

   incrementForceUpdate = ()=>{
      const forceUpdate = this.state.forceUpdate + 1;
      this.setState({forceUpdate});
   }

   getButtons = (expenseReport) => {
      return [
         <ButtonLink 
            key={'view-button' + expenseReport.id}
            backgroundColor="#525252" labelColor="#ffffff"
            label="View"
            to={'/expense-report/' + expenseReport.id} 
            style={buttonStyle}                             
         />,
         <RaisedButton
            key={'delete-button' + expenseReport.id}
            label='Archive'
            backgroundColor='#e60000'
            labelColor='#ffffff'                                     
            onClick={(e)=>this.openDeleteDialogBox(expenseReport.id, e)}
            style={buttonStyle} 
         /> 
      ];
   }

   render() {
       const columns = [
           {key: 'id', label: 'ID', 'sortable': true},
           {key: 'name', label: 'Name', 'sortable': true},
           {key: 'missionary_number', label: 'Account #', 'sortable': true},
           {key: 'type', label: 'Expense Type', 'sortable': true},
           {key: 'usd_amount', label: 'Amount', 'sortable': true},
           //{key: 'start_date', label: 'Start Date', 'sortable': true},
           //{key: 'end_date', label: 'End Date', 'sortable': false},
           {key: 'date_submitted', label: 'Date Submitted', 'sortable': false},
           //{key: 'processed', label: 'Processed', 'sortable': false},
           {key: 'buttons', label: '', 'sortable': false},
           {}
       ];

       const processedExpenseReports = _.values(this.props.expenseReports.processedExpenseReports);
       let processedExpenseReportsTable = processedExpenseReports.map( report => {
            return {
                id: report.id,
                name: report.user.name,
                missionary_number: report.user.profile ? report.user.profile.missionary_number : null,
                type: <ExpenseTypeTag type={report.type}/>,
                usd_amount: report.total_amount ? '$' + report.total_amount.toFixed(2) : null,
                //start_date: moment(report.start_date).format('MM/DD/YYYY'),
                //end_date: moment(report.end_date).format('MM/DD/YYYY'),
                date_submitted: report.date_submitted ? moment(report.date_submitted).format('MM/DD/YYYY') : null,
                //processed: report.processed ? <Icon type="done"/> : <Icon type="clear"/>,
                buttons: this.getButtons(report)
            }
       });
       processedExpenseReportsTable.sort(dateSubmittedTableSort);

      const actions_delete_expense_report = [
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#525252'}
            label="Cancel"
            onClick={this.handleClose} 
            style={{marginRight: 3}}                      
         />,
         <RaisedButton 
            labelColor="#ffffff"
            backgroundColor={'#d40a0a'}
            label="Archive"
            onClick={(event) => this.deleteExpenseReport(event)}                       
         />
      ];

       return (
           <div className="processed-expense-reports">
               <div className="list-view table-view">
						<Subheader className="table-style">Processed Expense Reports</Subheader>
                  <Table data={processedExpenseReportsTable} columns={columns} forceUpdate={this.state.forceUpdate}/>
               </div>
               <Dialog
                  actions={actions_delete_expense_report}
                  modal={true}
                  open={this.state.deleteExpenseReport}
                  contentStyle={{width: '90%'}}
                  actionsContainerStyle={{textAlign: 'center', paddingBottom: 20}}
               >
                  <Subheader className="curved-style">Are you sure you want to archive this expense report?</Subheader>
               </Dialog>
           </div>
       )
   }
}

export default connect(
   state => ({
       expenseReports: state.rootReducer.expenseReports
   })
)(ProcessedExpenseReports);
